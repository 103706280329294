import React, { useEffect } from 'react';
import Layout from '../components/Layout/Layout';
import CheckoutContent from '../components/CheckoutContent';
import { CheckoutProvider } from '../context/useCheckoutContext';
import { Spacer } from '../uiBook';

const CheckoutPage = () => {
  return (
    <Layout>
      <CheckoutProvider>
        <Spacer height={12} />
        <CheckoutContent />
      </CheckoutProvider>
    </Layout>
  );
};

export default CheckoutPage;
