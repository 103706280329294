import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Copy,
  FormBuilder,
  Header,
  PayPalButton,
  Spacer,
  StyledSpan,
} from '../../uiBook';
import { useCheckoutContext } from '../../context/useCheckoutContext';
import { useAppContext } from '../../context/useAppContext';

const CheckoutContent = (props) => {
  const [accepted, setAccepted] = useState(false);

  const handleCheckboxChange = (event) => {
    setAccepted(event.target.checked);
  };

  const TermsAndConditions = () => {
    return (
      <div
        style={{
          marginBottom: '24px',
        }}
      >
        <label>
          <input
            type="checkbox"
            checked={accepted}
            onChange={handleCheckboxChange}
          />{' '}
          I agree to the terms and conditions
        </label>
      </div>
    );
  };

  const {
    formFields,
    formError,
    shippingReady,
    basketWeight,
    countryCode,
    postalCode,
    paymentReady,
    paypalProps,
    orderTotal,
    invalidAddress,
    setPaymentReady,
    handleInfoSubmit,
    handleShippingSubmit,
    handleInsuranceSelect,
    setInvalidAddress,
  } = useCheckoutContext();

  const { basketValue } = useAppContext();

  const shippingCostProps = {
    handleChange: handleShippingSubmit,
    handleInsuranceSelect,
    postalCode,
    countryCode,
    shippingWeight: basketWeight,
    value: basketValue,
  };

  useEffect(() => {
    if (shippingReady) {
      window.scrollTo(0, 0);
    }
  }, [shippingReady]);

  if (invalidAddress) {
    return (
      <Container center padding="24px 16px">
        <Header level={3} fontSize={30}>
          We're sorry
        </Header>
        <Spacer height={24} />
        <Copy>
          We do not currently deliver to this region. Please contact us for more
          information.
        </Copy>
      </Container>
    );
  }

  return (
    <Container center padding="24px 16px">
      {!shippingReady && !paymentReady && (
        <>
          <Header level={3} fontSize={30}>
            Free shipping on all orders. All of our products are covered by
            specialist shipping insurance for delivery.
          </Header>
          <Spacer height={24} />
          <Copy>
            We need a few details to process your order. Please fill in the form
          </Copy>
          <Container maxWidth="600px">
            <FormBuilder
              fields={formFields}
              onSubmit={handleInfoSubmit}
              googleMapsApiKey="AIzaSyBvPR9E7DmEuJSQMsTFEF3rQJEmgeXGbt8"
              submitButtonText="Proceed to shipping"
            />
            <Spacer height={12} />
            <Copy>
              <StyledSpan color="red">{formError}</StyledSpan>
            </Copy>
          </Container>
        </>
      )}
      {shippingReady && !paymentReady && (
        <>
          <Header level={3} fontSize={30}>
            Shipping Information
          </Header>
          <Spacer height={24} />

          <Spacer height={24} />
          <Button onClick={() => setPaymentReady(true)}>
            Proceed to Payment
          </Button>
        </>
      )}
      {paymentReady && (
        <>
          <Container maxWidth="600px">
            <Header level={3} fontSize={30}>
              Payment Information
            </Header>
            <Copy>
              Your order total is{' '}
              <StyledSpan boldFont>£{orderTotal}</StyledSpan>
              <br />
              <br />
              Please accept the terms and conditions, and pay securely with
              PayPal below:
            </Copy>
            <Spacer height={24} />
            <TermsAndConditions />
            {accepted && <PayPalButton {...paypalProps} />}
            <Spacer height={24} />
            <Copy>
              We will send you an email confirmation once your order has been
              processed.
            </Copy>
          </Container>
        </>
      )}
    </Container>
  );
};

export default CheckoutContent;
