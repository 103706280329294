import axios from 'axios';
import { useEffect, useState } from 'react';
import constate from 'constate';
import { useAppContext } from './useAppContext';
import {
  adminGetData,
  adminUpload,
  handlePutRequest,
} from '../utils/adminUpload';
import { createHtml } from '../utils/emailCreator';
import { navigate } from 'gatsby';

const initialCheckoutData = {
  shippingAddress: {
    countryCode: null,
    postalCode: null,
  },
  shippingService: null,
  shippingInsurance: '0',
  shippingCost: null,
};

const useCheckout = () => {
  const [orderData, setOrderData] = useState(null);
  const [invalidAddress, setInvalidAddress] = useState(false);
  const [shippingReady, setShippingReady] = useState(false);
  const [paymentReady, setPaymentReady] = useState(false);
  const [formError, setFormError] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [checkoutData, setCheckoutData] = useState(initialCheckoutData);
  const [orderTotal, setOrderTotal] = useState(0);
  const [productDetails, setProductDetails] = useState(null);

  const {
    products,
    basketValue,
    basketWeight,
    basket,
    setBasket,
    setOrderStatus,
  } = useAppContext();

  const sheetId = process.env.GATSBY_GOOGLE_SHEET_ID;

  const formFields = [
    {
      name: 'firstName',
      label: '*First Name',
      type: 'text',
      required: true,
    },
    {
      name: 'lastName',
      label: '*Last Name',
      type: 'text',
      required: true,
    },
    {
      name: 'email',
      label: '*Email',
      type: 'text',
      required: true,
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'text',
      required: false,
    },
    {
      name: 'shippingAddress',
      label: '*Shipping Address',
      type: 'address',
      required: true,
    },
  ];

  const handleInfoSubmit = (data) => {
    setFormError(null);
    const { shippingAddress } = data || {};
    const { email, firstName, lastName, phone } = data || {};
    if (!shippingAddress?.countryCode) {
      setFormError('Please enter a valid shipping address.');
    } else {
      setShippingReady(true);
      setPaymentReady(true);
      setCheckoutData({
        ...checkoutData,
        email,
        firstName,
        lastName,
        phone,
        shippingAddress,
      });
      setPostalCode(shippingAddress.postalCode);
      setCountryCode(shippingAddress.countryCode);
    }
  };

  const handleShippingSubmit = (service, value) => {
    setCheckoutData({
      ...checkoutData,
      shippingService: service,
      shippingCost: value,
    });
  };

  const handleInsuranceSelect = (cost) => {
    setCheckoutData({
      ...checkoutData,
      shippingInsurance: cost,
    });
  };

  const sendOrderConfirmation = async (orderDetails, customerEmail) => {
    try {
      const response = await axios.post(
        `${process.env.GATSBY_ORDER_CONFIRMATION_URL}/send-order-confirmation`,
        {
          orderDetails,
          customerEmail,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error('Failed to send order confirmation');
    }
  };

  const handleSubmitOrderEmail = async (orderDetails, customerEmail) => {
    try {
      await sendOrderConfirmation(orderDetails, customerEmail);
      console.log('Order confirmation sent successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateOrdersSheet = () => {
    let lastOrder = 1001;
    if (orderData) {
      lastOrder = orderData[orderData.length - 1].ordernumber;
    }
    const { email, firstName, lastName, phone, shippingAddress } = checkoutData;
    const data = basket.map((item) => {
      return {
        orderstatus: 'processing',
        ordernumber: Number(lastOrder) + 1,
        orderdate: new Date().toISOString(),
        customerfirstname: firstName,
        customerlastname: lastName,
        customeremail: email,
        customerphone: phone,
        address1: shippingAddress.streetNumber,
        address2: shippingAddress.route,
        address3: '',
        city: shippingAddress.locality,
        code: shippingAddress.postalCode,
        country: shippingAddress.countryCode || 'GB',
        productsku: item.sku,
        itemtotal: item.price,
        carttotal: basketValue,
        shippingtotal: checkoutData.shippingCost,
        shippinginsurancetotal: checkoutData.shippingInsurance,
        ordertotal: orderTotal,
      };
    });
    adminUpload('orders', data, sheetId);
    setBasket([]);
    // Update product stock levels
    data.forEach((prod) => {
      const index = products.findIndex((item) => item.sku === prod.productsku);
      handlePutRequest(['processing'], `L${index + 2}`, 'products', sheetId);
    });
    // Send order confirmation email
    const address = {
      name: `${firstName} ${lastName}`,
      street: `${checkoutData.shippingAddress.streetNumber} ${checkoutData.shippingAddress.route}`,
      street2: `${checkoutData.shippingAddress.locality}`,
      street3: `${checkoutData.shippingAddress.postalCode}`,
      city: '',
      country: `${checkoutData.shippingAddress.countryCode}`,
    };
    handleSubmitOrderEmail(
      createHtml(
        productDetails,
        address,
        data?.[0].ordernumber,
        basketValue,
        checkoutData.shippingCost,
        orderTotal
      ),
      email
    );
    handleSubmitOrderEmail(
      createHtml(
        productDetails,
        address,
        data?.[0].ordernumber,
        basketValue,
        checkoutData.shippingCost,
        orderTotal
      ),
      'immediateimage@gmail.com'
    );
    navigate('/orderConfirm');
  };

  // fetch existing orders
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await adminGetData('orders', sheetId);
        setOrderData(data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get product details for order confirmation email
  useEffect(() => {
    const products = basket.map((item) => {
      return {
        name: item.title,
        price: item.price,
      };
    });
    setProductDetails(products);
  }, [basket]);

  // Calculate order total
  useEffect(() => {
    const { shippingCost, shippingInsurance } = checkoutData;
    const totalCost =
      Number(shippingCost) + Number(shippingInsurance) + basketValue;
    setOrderTotal(totalCost.toString());
  }, [basketValue, checkoutData]);

  useEffect(() => {
    // Check for invalid addresses in Germany and New York State
    if (countryCode === 'DE' && postalCode) {
      setInvalidAddress(true);
    }
    if (countryCode === 'US') {
      const zipCode = Number(postalCode);
      if (zipCode >= 10001 && zipCode <= 14925) {
        setInvalidAddress(true);
      }
    }
  }, [postalCode, countryCode]);

  const paypalProps = {
    clientId: process.env.GATSBY_PAYPAL_CLIENT_ID,
    value: orderTotal,
    onApprove: (data, details) => {
      handleUpdateOrdersSheet();
      setOrderStatus('processing');
    },
    onCancel: () => {
      setOrderStatus('cancelled');
      navigate('/orderConfirm');
    },
  };

  return {
    formFields,
    basket,
    formError,
    shippingReady,
    countryCode,
    postalCode,
    orderTotal,
    basketWeight: basketWeight?.toString(),
    paymentReady,
    paypalProps,
    invalidAddress,
    setPaymentReady,
    setBasket,
    handleInfoSubmit,
    handleShippingSubmit,
    handleInsuranceSelect,
    handleUpdateOrdersSheet,
    setInvalidAddress,
  };
};

const [CheckoutProvider, useCheckoutContext] = constate(useCheckout);
export { CheckoutProvider, useCheckoutContext };
