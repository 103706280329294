import axios from 'axios';
const url = 'https://google-sheets.onrender.com';

async function uploadData(sheet, values, id) {
  const appendData = async () => {
    try {
      const payload = values;
      await axios.post(`${url}/data?id=${id}&sheet=${sheet}`, payload);
    } catch (error) {
      console.error('Error appending data:', error);
    }
  };
  await appendData();
}

export const adminUpload = async (sheet, values, id) => {
  uploadData(sheet, values, id)
    .then(() => {
      console.log('Data uploaded successfully');
    })
    .catch((error) => {
      console.error('Failed to upload data:', error);
    });
};

export const adminGetData = async (sheet, id) => {
  try {
    const response = await axios.get(`${url}/data?id=${id}&sheet=${sheet}`);
    return response.data;
  } catch (error) {
    console.error('Error getting data:', error);
    return null;
  }
};

export const handlePutRequest = async (values, range, sheet, id) => {
  const requestBody = {
    values: [values],
  };

  try {
    await axios.put(
      `${url}/data?id=${id}&sheet=${sheet}&range=${range}`,
      requestBody
    );
    console.log('Data updated successfully');
  } catch (error) {
    console.error('Error updating data:', error);
  }
};
