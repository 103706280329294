const createProducts = (products) => {
  return products
    .map((product) => {
      return `
            <tr>
                <td>${product.name}</td>   
                <td>£${product.price}</td>
            </tr>
      `;
    })
    .join('');
};

export const createHtml = (
  products,
  address,
  orderId,
  cartTotal,
  shippingCost,
  grandTotal
) => {
  return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Order Confirmation</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                color: #333;
            }
            .container {
                padding: 20px;
            }
            .header {
                background-color: #f3f3f3;
                padding: 10px;
                text-align: center;
            }
            .order-details, .shipping-address {
                margin-top: 20px;
            }
            table {
                width: 100%;
                border-collapse: collapse;
            }
            th, td {
                text-align: left;
                padding: 8px;
                border-bottom: 1px solid #ddd;
            }
            @media screen and (max-width: 600px) {
                .container {
                    padding: 10px;
                }
                .header {
                    padding: 5px;
                }
                th, td {
                    padding: 4px;
                }
            }
        </style>
    </head>
    <body>
        <div class="container">
            <div class="header">
                <h2>Marion Ancient Art - Order Confirmation</h2>
            </div>
            <div class="order-details">
                <p>Thank you for your order, which is now being processed. We will send you an email when your order has been dispatched.</p>
            </div>
            <div class="order-details">
                <h3>Your Order Details</h3>
                <p>Order Number: ${orderId}</p>
                <table>
                    <tr>
                        <th>Product</th>
                    
                        <th>Price</th>
                    </tr>
                    ${createProducts(products)}
                </table>
            </div>
            <div class="shipping-address">
                <h3>Order Total</h3> 
                <p>Sub Total: £${cartTotal}</p>
                <p>Shipping: £${shippingCost}</p>
                <p>Total: £${grandTotal}</p>
            </div>
            <div class="shipping-address">
                <h3>Shipping Address</h3>
                <p>${address.name}</p>
                <p>${address.street}</p>
                ${address.street2 ? `<p>${address.street2}</p>` : ''}
                ${address.street3 ? `<p>${address.street3}</p>` : ''}
                <p>${address.city}</p>
                <p>${address.country}</p>
            </div>
        </div>
    </body>
    </html>
    
    `;
};
